import { SxStyleProp } from 'theme-ui';

export const GridLayout: SxStyleProp = {
  display: 'grid',
  gridTemplateAreas: [`'label' 'map' 'legal'`, `'label' 'map' 'legal'`, `'label map' 'legal legal'`],
  gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
  gridTemplateRows: 'repeat(2, auto)',
  maxWidth: '1280px',
  mx: 'auto',
  p: [4, '32px 16px'],
  rowGap: '20px',
  width: '100%',
};

export const LocationWrapper: SxStyleProp = {
  flexDirection: 'column',
  fontSize: '20px',
  gap: ['18px', '24px'],
  gridArea: 'label',
  height: 'auto',
  lineHeight: '1.2em',
  px: ['0', '0', '20px'],
  width: '100%',
};

export const Label: SxStyleProp = {
  fontFamily: 'tertiary',
  fontSize: '16px',
  fontWeight: '500',
  height: 'auto',
  lineHeight: '2',
  width: '100%',
};

export const LocationTextWrapper: SxStyleProp = {
  flexDirection: 'column',
  gap: ['10px', '18px'],
};

export const MapWrapper: SxStyleProp = {
  gridArea: 'map',
  height: ['300px', '300px', '624px'],
};

export const LegalText: SxStyleProp = {
  fontFamily: 'tertiary',
  fontSize: '16px',
  gridArea: 'legal',
};