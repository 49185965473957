import * as React from 'react';
import { filter as _filter } from 'lodash';
import { Box, Flex, Text } from '@lce/slice_v2';

import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import { MapIdProvider } from '@lce/intl-util/src/api/SelectedMapId';
import checkIfBrowser from '@lce/intl-util/src/checkIfBrowser';
import useLocation from '../../api/ContactPage/useLocation';
import useSiteConfig from '../../api/Config/useSiteConfig';
import Layout from '../../ui/ConnectedLayout';
import { useUpdateFranchiseeId } from '../../api/FranchiseeId/hooks/useUpdateFranchiseeId';
import Map from '../../ui/PageComponents/ContactUs/Map';
import {
  LocationButtons,
  LocationAddress,
  LocationHours,
  LocationPhone,
} from '../../ui/Patterns/StoreLocation/components';
import useOrderingModal from '../../api/OrderingModal/useOrderingModal';
import * as Styled from './StoreDetails.style';

export interface IStoreDetails extends IDefaultPageProps {
  pageContext: {
    originalPath?: string;
    franchisee: {
      name: string;
      originalId: string;
    };
  };
}

const StoreDetails: React.FC<IStoreDetails> = ({ ...props }) => {
  const { locale } = props.pathContext;
  const { franchisee } = props.pageContext;

  const { siteConfig: { googleMapKey }, direction } = useSiteConfig(locale);
  const modalData = useOrderingModal(locale);
  const { locationDefault, locationsList, mapDisplay, legalContent } = useLocation(locale);
  const isBrowser = checkIfBrowser();
  const slug = isBrowser ? `${ window.location.href.split('/').pop() }` : '';

  const location = _filter(locationsList, store => store.slug === `/${slug}`)[0];
  const mapLocation = _filter(mapDisplay.locations, store => store.slug === `/${slug}`)[0];
  const mapDisplayLocation = { ...mapDisplay, locations: [ mapLocation ] };

  const [ setFranchiseeId ] = useUpdateFranchiseeId();
  React.useEffect(() => {
    setFranchiseeId(franchisee.originalId);
  }, [ franchisee.originalId ]);

  if (!location) {
    return null;
  }

  return (
    <Layout {...props} pathContext={{ locale, slug: `/${slug}` }} showStartYourOrder={false}>
      <MapIdProvider>
        <Box data-testid="store-details" sx={Styled.GridLayout}>
          <Flex sx={Styled.LocationWrapper}>
            <LocationButtons
              deliveryButtonLabel={locationDefault.deliveryButtonLabel}
              deliveryOptions={location.deliveryOptions}
              modalContent={modalData}
              noPickupOrDeliveryText={locationDefault.noPickupOrDeliveryText}
              pickupButtonLabel={locationDefault.pickupButtonLabel}
              pickupLink={location.pickupLink}
            />
            <Box>
              <Text sx={Styled.Label}>{location.label}</Text>
              <Flex sx={Styled.LocationTextWrapper}>
                <LocationAddress
                  address={location.address}
                  directions={location.directions}
                  directionsButtonLabel={locationDefault.directionsButtonLabel}
                  modalContent={modalData}
                  name={location.name}
                  isLocationDetailPage={true}
                />
                <LocationHours
                  defaultHours={locationDefault.defaultHours}
                  hours={location.hours}
                  hoursLabel={locationDefault.hoursLabel}
                />
                <LocationPhone direction={direction} telephoneNumber={location.telephoneNumber} />
              </Flex>
            </Box>
          </Flex>
          <Box sx={Styled.MapWrapper}>
            <Map apiKey={googleMapKey} {...mapDisplayLocation} showSearchBar={false} />
          </Box>
          {legalContent.legalText && (
            <Box dangerouslySetInnerHTML={{ __html: legalContent.legalText }} sx={Styled.LegalText} />
          )}
        </Box>
      </MapIdProvider>
    </Layout>
  );
};

export default StoreDetails;
